@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  html, body, #root {
    height: 100%;
  }
